import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoreService } from 'src/app/shared/services/store.service';
import { SingleStoreCheckoutModalComponent } from 'src/app/single-store-checkout-modal/single-store-checkout-modal.component';

@Component({
  selector: 'app-delivery-detail',
  templateUrl: './delivery-detail.component.html',
  styleUrls: ['./delivery-detail.component.scss'],
})
export class DeliveryDetailComponent implements OnInit {
  storeurl: string = '';
  total: number = 0;
  count: number = JSON.parse(localStorage.getItem('cartCount')!);
  cart: any[] = JSON.parse(localStorage.getItem('cart')!);
  warning: boolean = false;
  deliveryTypes: string[] = [];
  selectedShipping: string = '';
  copy: boolean = false;
  delivery: string = '';
  states: any[] = [];
  cities: string[] = [];
  deliveryCharge: any;

  @Input() availableShipping!: string[];
  @Input() deliveryDetail!: FormGroup;
  @Input() order: any;
  @Output() shippingValueEmitter = new EventEmitter<string>();

  constructor(
    public store: StoreService,
    public http: HttpClient,
    public fb: FormBuilder
  ) {
    this.http.get<any[]>('./assets/shipping.json').subscribe((data) => {
      this.states = data;
    });
  }

  countries = ['Nigeria'];

  codes = ['+234'];

  selectedValue($event: any) {
    this.selectedShipping = $event.value;
    const pickupIndex = this.availableShipping.findIndex(
      (ship: string) => ship === 'PICKUP_IN_STORE'
    );
    if (this.availableShipping.length > 1) {
      if (this.selectedShipping === 'Ship') {
        pickupIndex === 1
          ? this.shippingValueEmitter.emit(this.availableShipping[0])
          : this.shippingValueEmitter.emit(this.availableShipping[1]);
      } else if (this.selectedShipping === 'select a shipping method') {
        this.shippingValueEmitter.emit(undefined);
      } else {
        pickupIndex === 1
          ? this.shippingValueEmitter.emit(this.availableShipping[1])
          : this.shippingValueEmitter.emit(this.availableShipping[0]);
      }
    } else if (this.availableShipping.length === 1) {
      this.shippingValueEmitter.emit(this.availableShipping[0]);
    }
  }

  ngOnInit(): void {
    this.availableShipping.map((option: any) => {
      if (option === 'PICKUP_IN_STORE') {
        this.deliveryTypes.push('Pickup in store');
      } else {
        this.deliveryTypes.push('Ship');
      }
    });
  }

  ngAfterContentChecked(): void {
    this.total = JSON.parse(localStorage.getItem('cartTotal')!);
  }
}
