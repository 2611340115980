import { HttpClient } from '@angular/common/http';
import {
  Component,
  Input,
  OnInit,
  AfterContentChecked,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { StoreService } from 'src/app/shared/services/store.service';

@Component({
  selector: 'app-cart-shipping',
  templateUrl: './cart-shipping.component.html',
  styleUrls: ['./cart-shipping.component.scss'],
})
export class CartShippingComponent implements OnInit, AfterContentChecked {
  storeurl: string = '';
  total: number = 0;
  count: number = JSON.parse(localStorage.getItem('cartCount')!);
  cart: any[] = JSON.parse(localStorage.getItem('cart')!);
  warning: boolean = false;
  selectedCarrier: any;
  selectedShippingPartner: any = ''; // shipping rate interface
  getrates = false;
  copy: boolean = false;
  delivery: string = '';
  states: any[] = [];
  cities: string[] = [];
  deliveryCharge: any;

  @Input() availableShipping!: string[];
  @Input() regions: any;
  @Output() deliveryValueEmmitter = new EventEmitter<string>();
  @Output() formValidEmitter = new EventEmitter<string>();
  @Output() selectedShippingPartnerEmitter = new EventEmitter<string>();
  @Input() shipping!: FormGroup;
  @Input() rates: any[] = []; // add interface for shipp rates

  constructor(
    public store: StoreService,
    public http: HttpClient,
    public fb: FormBuilder,
    private snackBar: SnackBarService
  ) {
    this.http.get<any[]>('./assets/shipping.json').subscribe((data) => {
      this.states = data;
    });
  }

  checkoutInfo: FormGroup = this.fb.group({
    addressDTO: this.fb.group({
      last_name: new FormControl('', [
        Validators.required,
        Validators.pattern("^[a-zA-Z-' ]+$"),
      ]),
      first_name: new FormControl('', [
        Validators.required,
        Validators.pattern("^[a-zA-Z-' ]+$"),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
      ]),
      country: new FormControl('NG', Validators.required),
      state: new FormControl('Select a state', Validators.required),
      city: new FormControl('', Validators.required),
      line1: new FormControl('', Validators.required),
      metadata: this.fb.group({
        delivery_note: new FormControl(''),
      }),
    }),
    // checkoutCode: new FormControl(''),
  });

  countries = ['Nigeria'];

  codes = ['+234'];

  subregions = [
    'South West',
    'South East',
    'South South',
    'North West',
    'North East',
    'North Central',
  ];

  getShippingRate() {
    this.store.fetchingRates = true;
    const currentValue = this.shipping.get('addressDTO')?.get('phone')?.value;
    const newValue = '+234';
    if (currentValue.includes('+234')) {
      this.formValidEmitter.emit(this.shipping.valid ? 'true' : 'false');
      return;
    } else {
      this.shipping
        .get('addressDTO')
        ?.get('phone')
        ?.setValue(newValue + currentValue);
      let getControl = this.shipping.get('addressDTO') as FormGroup;
      getControl?.get('phone')?.clearValidators();
      getControl?.get('phone')?.updateValueAndValidity();

      this.formValidEmitter.emit(this.shipping.valid ? 'true' : 'false');
    }
  }

  selectedRate($event: any) {
    this.selectedShippingPartner = $event.value;

    for (let rate of this.rates) {
      if (rate.rate_id === this.selectedShippingPartner) {
        this.selectedCarrier = rate;
      }
    }

    this.selectedShippingPartnerEmitter.emit(this.selectedCarrier);
  }

  deliveryFee() {
    const delivery = this.shipping.get('addressDTO')?.get('region')?.value;
    if (this.regions) {
      for (let location of this.regions) {
        if (delivery === location.area) {
          this.deliveryCharge = location.fee;
          const nestedForm = this.shipping
            .get('addressDTO')
            ?.get('metadata') as FormGroup;
          nestedForm.addControl(
            'delivery_fee',
            new FormControl(this.deliveryCharge ?? null)
          );
        }
      }
    } else if (this.rates?.length > 0 && this.selectedCarrier) {
      this.deliveryCharge = this.selectedCarrier.amount;
      const nestedForm = this.shipping
        .get('addressDTO')
        ?.get('metadata') as FormGroup;
      nestedForm.addControl(
        'delivery_fee',
        new FormControl(this.deliveryCharge ?? null)
      );
    }
  }

  stateChanged() {
    const state = this.shipping.get('addressDTO')?.get('state')?.value;

    const selectedState = this.states.find((s) => s.name === state);

    if (selectedState) {
      this.cities = selectedState.cities;
      this.checkoutInfo.patchValue({ addressDTO: { city: '' } });
    }
  }

  ngOnInit(): void {}

  ngAfterContentChecked(): void {
    this.deliveryFee();
    this.total = JSON.parse(localStorage.getItem('cartTotal')!);
    if (this.regions) {
      let getControl = this.shipping.get('addressDTO')?.get('region')
        ?.value as FormGroup;

      for (let location of this.regions) {
        if (getControl === location.area) {
          this.selectedShippingPartnerEmitter.emit(location.fee);
        }
      }
    }

    if (this.rates?.length > 0) {
      this.getrates = false;
    }
  }
}
